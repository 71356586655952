import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { setMessage } from "../message/Message";
import AuthService from "../../../services/AuthService";
import { jwtDecode } from "jwt-decode";

// const user = JSON.parse(localStorage.getItem("user"));

const initialState = {
  isLoggedIn: false,
  user: null,
  role: null,
  loading: false,
};

export const forgotPassword = createAsyncThunk(
  "auth/forgotPassword",
  async (email, thunkAPI) => {
    try {
      const response = await AuthService.forgotPassword(email);
      thunkAPI.dispatch(
        setMessage({ message: response.data.message, type: "success" })
      );
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message, type: "error" }));
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const verifyOtp = createAsyncThunk(
  "auth/verifyOtp",
  async ({ uuid, otp }, thunkAPI) => {
    try {
      const response = await AuthService.verifyOtp(uuid, otp);
      thunkAPI.dispatch(
        setMessage({ message: response.data.message, type: "success" })
      );
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message, type: "error" }));
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const resetPassword = createAsyncThunk(
  "auth/resetPassword",
  async ({ id, newPassword, confirmPassword }, thunkAPI) => {
    debugger;
    try {
      const response = await AuthService.resetPassword(
        id,
        newPassword,
        confirmPassword
      );
      thunkAPI.dispatch(
        setMessage({ message: response.data.message, type: "success" })
      );
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message, type: "error" }));
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const register = createAsyncThunk(
  "auth/register",
  async (
    {
      firstname,
      lastname,
      email,
      country,
      state,
      city,
      zipCode,
      address,
      addressProof,
      phoneNo,
      password,
    },
    thunkAPI
  ) => {
    try {
      debugger;
      const response = await AuthService.register(
        firstname,
        lastname,
        email,
        country,
        state,
        city,
        zipCode,
        address,
        addressProof,
        phoneNo,
        password
      );
      thunkAPI.dispatch(
        setMessage({ message: response.data.message, type: "success" })
      );
      return { user: response.data.data };
    } catch (error) {
      debugger;
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message, type: "error" }));
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const uploadAddressDocument = createAsyncThunk(
  "auth/uploadAddressDocument",
  async (file, thunkAPI) => {
    debugger;
    try {
      const formData = new FormData();
      formData.append("image", file); // 'addressProof' should match the key expected in the API

      const response = await AuthService.uploadAddressDocument(formData); // Call the upload API

      // Dispatch any additional actions based on the response, if needed
      thunkAPI.dispatch(setMessage("File uploaded successfully"));

      return response.data; // Return the response data
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      // thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const login = createAsyncThunk(
  "auth/login",
  async ({ email, password }, thunkAPI) => {
    debugger;
    try {
      const data = await AuthService.login(email, password);
      thunkAPI.dispatch(
        setMessage({ message: "Login Successfully", type: "success" })
      );
      const userDecode = data ? jwtDecode(data.data) : null;
      return {
        user: userDecode,
        role: userDecode ? userDecode.role : null,
        isLoggedIn: true,
      };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message, type: "error" }));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const fetchCountries = createAsyncThunk(
  "auth/fetchCountries",
  async (_, thunkAPI) => {
    try {
      const response = await AuthService.fetchCountries();
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

const AuthSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    // [login.pending]: (state, action) => {
    //   debugger;
    //   state.isLoggedIn = false;
    //   state.loading = true;
    // },
    // [login.fulfilled]: (state, action) => {
    //   state.isLoggedIn = true;
    //   state.loading = false;

    //   console.log("Action Payload:", action.payload); // Log the entire payload
    //   const userDecode = action.payload.user; // Get decoded user from payload

    //   if (userDecode) {
    //     console.log("Decoded User:", userDecode); // Log the decoded user
    //     state.user = userDecode;
    //     state.role = userDecode.role;
    //   } else {
    //     console.log("User is null");
    //   }
    // },
    // [login.rejected]: (state, action) => {
    //   state.loading = false;
    //   state.isLoggedIn = false;
    //   state.user = null;
    //   state.role = null;
    // },
    [register.fulfilled]: (state, action) => {
      state.isLoggedIn = false;
      state.user = action.payload.user;
    },
    [register.rejected]: (state, action) => {
      state.isLoggedIn = false;
      state.user = null;
    },
  },
  extraReducers: (builder) => {
    builder
      // Add cases for OTP verification
      .addCase(login.pending, (state) => {
        console.log("Login Pending");
        state.loading = true;
        state.isLoggedIn = false;
      })
      .addCase(login.fulfilled, (state, action) => {
        debugger;
        console.log("Login Fulfilled:", action.payload); // Log payload
        state.isLoggedIn = true;
        state.loading = false;

        const userDecode = action.payload.user;
        if (userDecode) {
          console.log("Decoded User:", userDecode); // Log decoded user
          state.user = userDecode;
          state.role = userDecode.role;
        }
      })
      .addCase(login.rejected, (state) => {
        console.log("Login Rejected");
        state.loading = false;
        state.isLoggedIn = false;
      })
      .addCase(verifyOtp.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(verifyOtp.fulfilled, (state, action) => {
        state.loading = false;
        state.isLoggedIn = true;
        state.user = action.payload.user;
      })
      .addCase(verifyOtp.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || "Failed to verify OTP";
      })
      .addCase(resetPassword.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(resetPassword.fulfilled, (state) => {
        state.loading = false;
        state.success = "Password changed successfully";
      })
      .addCase(resetPassword.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || "Failed to reset password";
      })
      .addCase(register.pending, (state) => {
        state.loading = true;
      })
      .addCase(register.fulfilled, (state, action) => {
        state.loading = false;
        state.isLoggedIn = true;
        state.user = action.payload.user;
      })
      .addCase(register.rejected, (state, action) => {
        state.loading = false;
        state.isLoggedIn = false;
        state.error = action.payload || "Failed to register";
      })
      .addCase(fetchCountries.fulfilled, (state, action) => {
        state.countries = action.payload;
      })
      .addCase(fetchCountries.rejected, (state, action) => {
        state.countries = [];
      })
      .addCase(uploadAddressDocument.pending, (state) => {
        state.loading = true;
      })
      // In the extraReducers part of your slice
      .addCase(uploadAddressDocument.fulfilled, (state, action) => {
        state.loading = false;
        const data = action.payload.data;
        state.addressProof = data; // Or handle the response appropriately
      })
      .addCase(uploadAddressDocument.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || "Failed to upload address document";
      })
      .addCase(forgotPassword.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.success = false;
      })
      .addCase(forgotPassword.fulfilled, (state) => {
        state.loading = false;
        state.success = true;
        state.error = null;
      })
      .addCase(forgotPassword.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.success = false;
      });
  },
});

const { reducer } = AuthSlice;
export default reducer;

import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Nav } from "reactstrap";
import NoteListItem from "./NoteListItem";
import {
  fetchNotes,
  SelectNote,
  DeleteNote,
} from "../../../store/apps/notes/NotesSlice";
import { createSelector } from "reselect";

const selectNotesReducer = (state) => state.notesReducer;

const selectFilteredNotes = createSelector(
  [selectNotesReducer],
  (notesReducer) => {
    const { notes, noteSearch } = notesReducer;
    if (noteSearch !== "") {
      return notes.filter(
        (t) =>
          !t.deleted &&
          t.title
            .toLocaleLowerCase()
            .concat(" ")
            .includes(noteSearch.toLocaleLowerCase())
      );
    }
    return notes.filter((t) => !t.deleted);
  }
);

const selectActiveNote = createSelector(
  [selectNotesReducer],
  (notesReducer) => notesReducer.notesContent
);

const NoteList = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchNotes());
  }, [dispatch]);

  const notes = useSelector(selectFilteredNotes);
  const active = useSelector(selectActiveNote);

  if (!notes) {
    return <div>Loading...</div>;
  }

  if (notes.length === 0) {
    return <div>No notes</div>;
  }

  return (
    <Nav>
      {notes.map((note) => {
        const noteDateFormatted = new Date(note.datef).toLocaleDateString(
          undefined,
          {
            weekday: "short",
            year: "numeric",
            month: "short",
          }
        );

        return (
          <NoteListItem
            key={note.id}
            {...note}
            active={active}
            onClick={() => dispatch(SelectNote(note.id))}
            onDeleteClick={() => dispatch(DeleteNote(note.id))}
            noteTitle={note.title}
            noteColor={note.color}
            noteDatef={noteDateFormatted}
          />
        );
      })}
    </Nav>
  );
};

export default NoteList;

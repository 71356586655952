import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { setMessage } from "../message/Message";
import RecipientService from "../../../services/AdminServices/RecipientService";



const initialState = {
  data: [],
  //allUserData: [],
  loading: false,
};

export const recipientsUser = createAsyncThunk(
  "recipient/recipientsUser",
  async (thunkAPI) => {
    try {
      const response = await RecipientService.recipientUsersWiseData();
      return { data: response.data.data }; 
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);


export const sendConnectionRequest = createAsyncThunk(
    `recipient/sendConnectionRequest`,
    async ({ userId, recipientId },thunkAPI ) => {
      try {
        const response = await RecipientService.sendConnection(
            userId,
            recipientId
        );
        return { data3: response.data.message }; 
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
  );


  export const fetchAllUsers = createAsyncThunk(
    "recipient/fetchAllUsers",
    async (thunkAPI) => {
      try {
        const response = await RecipientService.allUserData();
        //console.log("data1 checking")
        return { data1: response.data.data }; 
        
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
  );


  export const inviteUser = createAsyncThunk(
    "recipient/inviteUser",
    async (
      {
        name,
        email,
        mobileNumber,
        organisation,
      },
      thunkAPI
    ) => {
      try {
        debugger;
        const response = await RecipientService.inviteUser(
            name,
            email,
            mobileNumber,
            organisation,
          );
        // thunkAPI.dispatch(
        //   setMessage({ data4: response.data.data, type: "success" })
        // );
        thunkAPI.dispatch(
            setMessage({ message: response.data.message, type: "success" })
          );
        return { data4: response.data.message }; 
      } catch (error) {
        debugger;
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        //thunkAPI.dispatch(setMessage({ message, type: "error" }));
        //thunkAPI.dispatch(setMessage(message));
        thunkAPI.dispatch(setMessage({ message, type: "error" }));
        return thunkAPI.rejectWithValue(message);
      }
    }
  );


const RecipientSlice = createSlice({
  name: "recipient",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(recipientsUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(recipientsUser.fulfilled, (state, action) => {
        state.data = action.payload.data; 
        state.loading = false;
      })
      .addCase(recipientsUser.rejected, (state) => {
        state.data = [];
        state.loading = false;
      })
      .addCase(sendConnectionRequest.pending, (state) => {
        state.loading = true;
      })
      .addCase(sendConnectionRequest.fulfilled, (state, action) => {
        state.data3 = action.payload.data3; 
        state.loading = false;
      })
      .addCase(sendConnectionRequest.rejected, (state) => {
        state.data3 = [];
        state.loading = false;
      })
      .addCase(fetchAllUsers.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchAllUsers.fulfilled, (state, action) => {
        state.data1 = action.payload.data1; 
        state.loading = false;
      })
      .addCase(fetchAllUsers.rejected, (state) => {
        state.data1 = [];
        state.loading = false;
      })
      .addCase(inviteUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(inviteUser.fulfilled, (state, action) => {
        state.data4 = action.payload.data4; 
        state.loading = false;
      })
      .addCase(inviteUser.rejected, (state) => {
        state.data4 = [];
        state.loading = false;
      })
  },
});

const { reducer } = RecipientSlice;
export default reducer;

import http from "../../baseUrl/httpcommon";

const requestForApproval = () => {
  debugger;
  return http.get(
    "/api/users/allusers?pageNumber=0&pageSize=15&sortBy=UserId&sortDir=desc"
  );
};

const userStatusChange = (userId, status) => {
  return http.patch(`/api/users/${userId}/status?status=${status}`);
};

const updateStatusChange = (uuid, verificationStatusEnum, comment) => {
  debugger;
  return http.put(`/api/users/status/active`, {
    uuid,
    verificationStatusEnum,
    comment,
  });
};

const RequestService = {
  requestForApproval,
  userStatusChange,
  updateStatusChange,
};

export default RequestService;

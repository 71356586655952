import { createSelector } from "reselect";

const selectCustomizer = (state) => state.customizer;

export const selectDirection = createSelector(
  [selectCustomizer],
  (customizer) => customizer.isRTL
);

export const selectIsMode = createSelector(
  [selectCustomizer],
  (customizer) => customizer.isDark
);

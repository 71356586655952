import axios from "axios";

export default axios.create({
  //  baseURL: "http://192.168.1.40:8081/",
  //baseURL: "http://localhost:8081/",
  //baseURL: "http://localhost:3000/",
  //baseURL: "http://192.168.1.31:8081",
  baseURL: "https://epostzap-api.digitalnoticeboard.biz/UserService-api/",
  headers: {
    "Content-Type": "application/json",
  },
});

import { configureStore } from "@reduxjs/toolkit";
import NotesReducer from "./apps/notes/NotesSlice";
import CustomizerReducer from "./customizer/CustomizerSlice";
import AuthSlice from "./apps/auth/AuthSlice";
import MessageSlice from "./apps/message/Message";
import RequestSlice from "./apps/admin/UserSlice";
import SettingSlice from "../store/apps/setting/SettingSlice"
import UserSlice from "../store/apps/user/UserSlice"
import { useReducer } from "react";
import RecipientSlice  from "../store/apps/recipients/RecipientSlice";



export const store = configureStore({
  reducer: {
    customizer: CustomizerReducer,
    notesReducer: NotesReducer,
    authReducer: AuthSlice,
    messageReducer: MessageSlice,
    requestsReducer: RequestSlice,
    setting: SettingSlice,
    userReducer: UserSlice,
    recipientReducer: RecipientSlice,
  },
});

export default store;

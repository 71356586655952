import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { setMessage } from "../message/Message";
import UserService from "../../../services/AdminServices/UserService";

const initialState = {
  user: null,
  loading: false,
  error: null,
};

export const users = createAsyncThunk(
  "userdata/users",
  async ({ userId }, thunkAPI) => {
    debugger;
    try {
      const response = await UserService.users(userId);
      // thunkAPI.dispatch(
      //   setMessage({ message: response.data.message, type: "success" })
      // );
      return response.data.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message, type: "error" }));
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// export const changePassword = createAsyncThunk(
//   "userdata/changePassword",
//   async ({ userId }, thunkAPI) => {
//     debugger;
//     try {
//       const response = await UserService.users(userId);
//       thunkAPI.dispatch(
//         setMessage({ message: response.data.message, type: "success" })
//       );
//       return response.data.data;
//     } catch (error) {
//       const message =
//         (error.response &&
//           error.response.data &&
//           error.response.data.message) ||
//         error.message ||
//         error.toString();
//       thunkAPI.dispatch(setMessage({ message, type: "error" }));
//       return thunkAPI.rejectWithValue(message);
//     }
//   }
// );

export const profile = createAsyncThunk(
  "userdata/profile",
  async ({ userId, image }, thunkAPI) => {
    debugger;
    try {
      const response = await UserService.profile(userId, image);
      thunkAPI.dispatch(
        setMessage({ message: response.data.message, type: "success" })
      );
      return response.data.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message, type: "error" }));
      return thunkAPI.rejectWithValue(message);
    }
  }
);

const UserSlice = createSlice({
  name: "userdata",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(users.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(users.fulfilled, (state, action) => {
        debugger;
        state.loading = false;
        state.isLoggedIn = true;
        state.user = action.payload;
      })
      .addCase(users.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || "Failed";
      })
      // Case for 'profile' thunk
      .addCase(profile.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(profile.fulfilled, (state, action) => {
        state.loading = false;
        state.isLoggedIn = true;

        // Check if state.user is null before updating the imageUrl
        if (state.user) {
          state.user.imageUrl = action.payload; // Update the user imageUrl
        } else {
          state.user = { imageUrl: action.payload }; // Initialize user with the imageUrl if null
        }
      })
      .addCase(profile.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || "Failed";
      });
  },
});

const { reducer } = UserSlice;
export default reducer;

import http from "../../baseUrl/httpcommon";


const recipientUsersWiseData = (userId) => {
  return http.get(
    "/api/users/22/recipients"
  );
};

const sendConnection = (userId,recipientId) => {
    return http.post(
      `/api/users/${userId}/recipients/${recipientId}/request`
    );
  };

  const allUserData = () => {
    return http.get(
      "/api/users/allusers?pageNumber=0&pageSize=10&sortBy=UserId&sortDir=desc"
    );
  };

  const inviteUser = (
    name,
    email,
    mobileNumber,
    organisation,
  ) =>
    http.post("/api/users/22/invite", {
        name,
        email,
        mobileNumber,
        organisation,
    });

const RecipientService = {
    recipientUsersWiseData,
    sendConnection,
    allUserData,
    inviteUser,
};

export default RecipientService;

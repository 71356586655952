import { Suspense } from "react";
import { useSelector } from "react-redux";
import { useRoutes } from "react-router-dom";
import Loader from "./layouts/loader/Loader";
import ThemeSelector from "./layouts/theme/ThemeSelector";
import ThemeRoutes from "./routes/Router";
import { selectDirection, selectIsMode } from "./selectors";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  const routing = useRoutes(ThemeRoutes);
  const direction = useSelector(selectDirection);
  const isMode = useSelector(selectIsMode);
  return (
    <Suspense fallback={<Loader />}>
      <div
        className={`${direction ? "rtl" : "ltr"} ${isMode ? "dark" : ""}`}
        dir={direction ? "rtl" : "ltr"}
      >
        <ThemeSelector />
        {routing}
        <ToastContainer
        position="top-right"
        autoClose={5000}
        closeOnClick
        theme="dark"
        />
      </div>
    </Suspense>
  );
}

export default App;

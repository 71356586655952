import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { setMessage } from "../message/Message";
import RequestService from "../../../services/AdminServices/RequestService";

const initialState = {
  data: [],
  loading: false,
};

export const requestForApproval = createAsyncThunk(
  "admin/requests",
  async (thunkAPI) => {
    try {
      const response = await RequestService.requestForApproval();
      return { data: response.data.data }; // Make sure response format is correct
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const changeUserStatus = createAsyncThunk(
  "requests/changeUserStatus",
  async ({ userId, newStatus }, thunkAPI) => {
    try {
      const response = await RequestService.userStatusChange(userId, newStatus);
      return response.data.data; // Make sure this matches your reducer logic
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const updateUserStatus = createAsyncThunk(
  "requests/updateUserStatus",
  async ({ uuid, verificationStatusEnum, comment }, thunkAPI) => {
    debugger;
    try {
      const response = await RequestService.updateStatusChange(
        uuid,
        verificationStatusEnum,
        comment
      );
      return response.data.data; // Make sure this matches your reducer logic
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

const RequestSlice = createSlice({
  name: "admin",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Handling requestForApproval API call
      .addCase(requestForApproval.pending, (state) => {
        state.loading = true;
      })
      .addCase(requestForApproval.fulfilled, (state, action) => {
        state.data = action.payload.data; // Populate data with user list
        state.loading = false;
      })
      .addCase(requestForApproval.rejected, (state) => {
        state.data = [];
        state.loading = false;
      })

      // Handling changeUserStatus API call
      .addCase(changeUserStatus.pending, (state) => {
        state.loading = true;
      })
      .addCase(changeUserStatus.fulfilled, (state, action) => {
        debugger;
        state.loading = false;
        const updatedUser = action.payload;
        const index = state.data.findIndex((user) => {
          return user.id === updatedUser.userId;
        });

        // If user exists, update the status
        if (index !== -1) {
          state.data[index] = { ...state.data[index], ...updatedUser };
        }
        console.log("Updated user status in reducer:", state.data[index]);
      })
      .addCase(changeUserStatus.rejected, (state) => {
        state.loading = false; // Handle error case, e.g., show an error message
      })
      .addCase(updateUserStatus.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateUserStatus.fulfilled, (state, action) => {
        debugger;
        state.loading = false;
        const updatedUser = action.payload;
        const index = state.data.findIndex((user) => {
          return user.id === updatedUser.userId;
        });

        // If user exists, update the status
        if (index !== -1) {
          state.data[index] = { ...state.data[index], ...updatedUser };
        }
        console.log("Updated user status in reducer:", state.data[index]);
      })
      .addCase(updateUserStatus.rejected, (state) => {
        state.loading = false; // Handle error case, e.g., show an error message
      });
  },
});

const { reducer } = RequestSlice;
export default reducer;

import http from "../baseUrl/httpcommon";

const login = (email, password) => {
  debugger;
  debugger;
  return http
    .post("auth/token", {
      email,
      password,
    })
    .then((response) => {
      const token = response.data.data;

      if (token) {
        const base64Url = token.split(".")[1];
        const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
        const jsonPayload = decodeURIComponent(
          atob(base64)
            .split("")
            .map((c) => "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2))
            .join("")
        );
        const decodedData = JSON.parse(jsonPayload);
        console.log(decodedData);
        localStorage.setItem("user", JSON.stringify(decodedData));
      }

      return response.data;
    });
};

const logout = () => {
  debugger;
  localStorage.removeItem("user");
};

const forgotPassword = (email) =>
  http.post("/api/users/forgotpassword", { email });

const verifyOtp = (uuid, otp) => {
  debugger;
  return http.post("/api/users/verify", { uuid, otp });
};

const resetPassword = async (id, newPassword, confirmPassword) => {
  debugger;
  return http.post("/api/users/resetpassword", {
    id,
    newPassword,
    confirmPassword,
  });
};

const register = (
  firstname,
  lastname,
  email,
  country,
  state,
  address,
  addressProof,
  mobile,
  password
) =>
  http.post("auth/register", {
    firstname,
    lastname,
    email,
    country,
    state,
    address,
    addressProof,
    mobile,
    password,
  });

const fetchCountries = () => http.get("/country/allCountries");

const uploadAddressDocument = (formData) => {
  debugger;
  return http.post("/api/users/signup/upload/address/doc", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

const AuthService = {
  register,
  login,
  logout,
  forgotPassword,
  verifyOtp,
  resetPassword,
  fetchCountries,
  uploadAddressDocument,
};

export default AuthService;

import * as Icon from "react-feather";

const SidebarData = [
  {
    title: "Home",
    href: "/dashboard/home",
    id: 1,
    suffix: "4",
    suffixColor: "bg-cyan rounded-pill text-dark-white",
    icon: <Icon.Home />,
    collapisble: false,
    role: "ROLE_CLIENT",
  },
  {
    title: "Notes",
    href: "/apps/notes",
    icon: <Icon.FileText />,
    id: 2.1,
    collapisble: false,
    role: "ROLE_CLIENT",
  },
  {
    title: "Recipients",
    href: "/apps/recipients",
    icon: <Icon.Users />,
    id: 2.2,
    collapisble: false,
    role: "ROLE_CLIENT",
  },
  {
    title: "Sent Files",
    href: "/apps/sentfiles",
    icon: <Icon.Send />,
    id: 2.3,
    collapisble: false,
    role: "ROLE_CLIENT",
  },

  {
    title: "Requests",
    href: "/apps/requests",
    icon: <Icon.User />,
    id: 2.3,
    collapisble: false,
    role: "ROLE_ADMIN",
  },

  {
    title: "Settings",
    href: "/apps/settings",
    icon: <Icon.Settings />,
    id: 6.5,
    collapisble: false,
  },
  {
    title: "Storage",
    href: "/auth/maintanance",
    icon: <Icon.Cloud />,
    id: 6.5,
    collapisble: false,
    role: "ROLE_CLIENT",
  },
];

export default SidebarData;

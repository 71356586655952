import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { setMessage } from "../message/Message";
import SettingService from "../../../services/AdminServices/SettingService";

const user = JSON.parse(localStorage.getItem("user"));

const initialState = user
  ? { isLoggedIn: true, user }
  : { isLoggedIn: false, user: null };

export const changePassword = createAsyncThunk(
  "setting/changePassword",
  async ({ email, oldPassword, newPassword }, thunkAPI) => {
    debugger;
    try {
      const response = await SettingService.changePassword(
        email,
        oldPassword,
        newPassword
      );
      thunkAPI.dispatch(
        setMessage({ message: response.data.message, type: "success" })
      );
      return response.data;
    } catch (error) {
      debugger;
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message, type: "error" }));
      return thunkAPI.rejectWithValue(message);
    }
  }
);

const SettingSlice = createSlice({
  name: "setting",
  initialState,
  reducers: {
    // Optional reducers here
  },
  extraReducers: (builder) => {
    builder
      .addCase(changePassword.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(changePassword.fulfilled, (state, action) => {
        state.loading = false;
        state.isLoggedIn = true;
        state.user = action.payload.user;
      })
      .addCase(changePassword.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || "Failed to change password";
      });
  },
});

const { reducer } = SettingSlice;
export default reducer;

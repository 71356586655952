import React from "react";
import * as Icon from "react-feather";

import {
  Button,
  Nav,
  Progress,
  // UncontrolledDropdown,
  // DropdownItem,
  // DropdownToggle,
  // DropdownMenu,
} from "reactstrap";
// import { User, FileText, Star, Settings, Droplet } from "react-feather";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import SimpleBar from "simplebar-react";
import SidebarData from "../sidebardata/SidebarData";
import Logo from "../../logo/Logo";
import { ToggleMobileSidebar } from "../../../store/customizer/CustomizerSlice";
import NavItemContainer from "./NavItemContainer";
import NavSubMenu from "./NavSubMenu";

// import user1 from "../../../assets/images/users/user4.jpg";

const Sidebar = () => {
  debugger;
  const location = useLocation();
  const currentURL = location.pathname.split("/").slice(0, -1).join("/");

  //const [collapsed, setCollapsed] = useState(null);
  // const toggle = (index) => {
  //   setCollapsed(collapsed === index ? null : index);
  // };

  const activeBg = useSelector((state) => state.customizer.sidebarBg);
  const isFixed = useSelector((state) => state.customizer.isSidebarFixed);
  const dispatch = useDispatch();

  // // Get the roles and loading state from Redux
  // const { roles, isLoading } = useSelector((state) => ({
  //   roles: state.authReducer.role,
  //   isLoading: state.authReducer.isLoading,
  // }));

  // // Check if loading is done and roles are not empty or undefined
  // if (isLoading || !roles || roles.length === 0) {
  //   return <div>Loading sidebar...</div>; // Show a loading message or spinner
  // }

  const user = localStorage.getItem("user");

  // Parse the user data if it exists
  const parsedUser = user ? JSON.parse(user) : null;

  // Access the user's roles
  const userRoles = parsedUser?.role;
  // Extract role names from the list of role objects
  const roleNames = userRoles?.map((role) => role.name);

  // Filter SidebarData based on the user's roles
  const filteredSidebarData = SidebarData.filter((item) => {
    if (roleNames?.includes("ROLE_ADMIN")) {
      return true; // Admin sees all items
    } else if (roleNames?.includes("ROLE_MODERATOR")) {
      return item.role === "ROLE_MODERATOR" || item.role === "ROLE_CLIENT"; // Moderator sees certain items
    } else {
      return item.role === "ROLE_CLIENT"; // Regular users see only client-level items
    }
  });

  return (
    <div
      className={`sidebarBox shadow bg-${activeBg} ${
        isFixed ? "fixedSidebar" : ""
      }`}
    >
      <SimpleBar style={{ height: "100%" }}>
        {/********Logo*******/}
        <div className="d-flex p-3 align-items-center">
          <Logo />
          <Button
            size="sm"
            className="d-xs-block d-sm-block d-md-block d-lg-none d-xl-none ms-auto btn-close"
            aria-label="close"
            onClick={() => dispatch(ToggleMobileSidebar())}
          />
        </div>
        {/********Sidebar Content*******/}
        <div className="py-4 text-center profile-area">
          <Button color="blue">
            <Icon.UploadCloud /> Upload
          </Button>
        </div>
        <div>
          <Nav vertical className={activeBg === "white" ? "" : "lightText"}>
            {filteredSidebarData.map((navi) => {
              if (navi.caption) {
                return (
                  <div
                    className="navCaption fw-bold text-uppercase mt-4"
                    key={navi.caption}
                  >
                    {navi.caption}
                  </div>
                );
              }
              if (navi.children) {
                return (
                  <NavSubMenu
                    key={navi.id}
                    icon={navi.icon}
                    title={navi.title}
                    items={navi.children}
                    suffix={navi.suffix}
                    suffixColor={navi.suffixColor}
                    // toggle={() => toggle(navi.id)}
                    // collapsed={collapsed === navi.id}
                    isUrl={currentURL === navi.href}
                  />
                );
              }
              return (
                <NavItemContainer
                  key={navi.id}
                  //toggle={() => toggle(navi.id)}
                  className={
                    location.pathname === navi.href ? "activeLink" : ""
                  }
                  to={navi.href}
                  title={navi.title}
                  suffix={navi.suffix}
                  suffixColor={navi.suffixColor}
                  icon={navi.icon}
                />
              );
            })}
          </Nav>
          <div
            style={{ padding: "0px 10px" }}
            className="py-4 text-center profile-area"
          >
            <Progress
              className="my-3"
              style={{
                height: "16px",
                borderRadius: 8,
              }}
              value={45}
            />
            <span style={{ padding: "0px 28px" }}>10 GB of 100GB used</span>
            <Button
              color="light-primary"
              className="me-2 btn rounded-pill mt-2"
              block
              size="md"
            >
              Get more storage
            </Button>
          </div>
        </div>
      </SimpleBar>
    </div>
  );
};

export default Sidebar;

import http from "../../baseUrl/httpcommon";

const users = (userId) => {
  return http.get("/api/users/" + userId);
};

const profile = (userId, image) => {
  const formData = new FormData();
  formData.append("image", image);
  return http.put(`/api/users/profile/photo?userId=${userId}`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

const changePassword = (email, oldPassword, newPassword) => {
  return http.post("/api/users/changepassword", {
    email,
    oldPassword,
    newPassword,
  });
};

const UserService = {
  users,
  profile,
  changePassword,
};

export default UserService;

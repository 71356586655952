import http from "../../baseUrl/httpcommon";

const changePassword = (email, oldPassword, newPassword) => {
  const user = JSON.parse(localStorage.getItem("user"));
  const token = user?.accessToken;

  return http.post("/api/users/changepassword", {
    email,
    oldPassword,
    newPassword,
  });
};

const SettingService = {
  changePassword,
};

export default SettingService;

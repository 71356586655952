import React, { useEffect, useState } from "react";
import { DropdownItem } from "reactstrap";
import { User, Settings, Droplet, Lock } from "react-feather";
import user1 from "../../assets/images/users/user1.jpg";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { users } from "../../store/apps/user/UserSlice";

const ProfileDD = () => {
  // const [userDetails, setUserDetails] = useState({
  //   firstname: "",
  //   lastname: "",
  //   email: "",
  // });

  const dispatch = useDispatch();

  useEffect(() => {
    const storedUser = JSON.parse(localStorage.getItem("user"));
    const userId = storedUser?.id;
    if (userId) {
      dispatch(users({ userId }));
    }
  }, [dispatch]);

  // useEffect(() => {
  //   const storedUser = JSON.parse(localStorage.getItem("user")) || {};
  //   const {
  //     firstname = "John",
  //     lastname = "Deo",
  //     email = "info@wrappixel.com",
  //   } = storedUser;

  //   setUserDetails({ firstname, lastname, email });
  // }, []);

  const { user, loading, error } = useSelector((state) => state.userReducer);

  return (
    <div>
      <div className="d-flex gap-3 p-3 border-bottom pt-2 align-items-center">
        <img
          src={user?.imageUrl}
          alt="user"
          className="rounded-circle"
          width="55"
        />

        <span>
          <h5 className="mb-0 fw-medium">{user?.firstName}</h5>
          <small className="text-muted">{user?.email}</small>
        </span>
      </div>
      {/* <DropdownItem className="px-4 py-3">
        <User size={20} className="text-muted" />
        &nbsp; My Profile
      </DropdownItem> */}
      {/* <DropdownItem className="px-4 py-3">
        <FileText size={20} className="text-muted" />
        &nbsp; Edit Profile
      </DropdownItem> */}
      <Link
        to={`/settings/changePassword`}
        className="ms-auto text-decoration-none"
      >
        <DropdownItem className="px-4 py-3">
          <Lock size={20} className="text-muted" />
          &nbsp; Change Password
        </DropdownItem>
      </Link>

      {/* <DropdownItem className="px-4 py-3">
        <Droplet size={20} className="text-muted" />
        &nbsp; Customize
      </DropdownItem> */}
      <DropdownItem divider />
      <Link to={`/apps/settings`} className="ms-auto text-decoration-none">
        <DropdownItem className="px-4 py-3">
          <Settings size={20} className="text-muted" />
          &nbsp; Settings
        </DropdownItem>
        <DropdownItem divider />
      </Link>
    </div>
  );
};

export default ProfileDD;
